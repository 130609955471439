import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// Core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import targetSectionStyle from "assets/jss/material-kit-pro-react/pages/targetPageSections/targetPageSectionStyle.js";

const useStyles = makeStyles(targetSectionStyle);

export default function SectionArticulation({ ...rest }) {
  const classes = useStyles();
  return (
    <div className={"cd-section"} {...rest} >
      <div className={classes.targetContent}>
        <div className={classes.container}>
          <h2 className={classes.title}>Articulatiestoornissen</h2>
          <GridContainer className={classes.description}>

            <GridItem md={4} sm={4}>
              <h3 className={classes.title}>Samengevat</h3>
              <p>
                Bij spraakproblemen of articulatieproblemen worden bepaalde klanken of klankcombinaties niet goed uitgesproken. De klanken worden dan vervangen door andere klanken, worden weggelaten of vervormd. Dit kan zowel bij kinderen, als bij volwassenen voorkomen.
              </p>
              <p>
                We spreken van een articulatiestoornis als iemand afwijkingen vertoont in zijn articulatie in vergelijking met leeftijdgenoten. Een kind van vier jaar mag bijvoorbeeld nog problemen hebben met de [s], [l], en [r] en met medeklinkercombinaties.
              </p>
              <p>
                Daarnaast is het ook mogelijk dat studenten of beroepssprekers hun articulatie nog verder komen verfijnen.
              </p>
            </GridItem>

            <GridItem md={8} sm={8}>
              <h3 className={classes.title}></h3>
              <p>
                Articulatiestoornissen kunnen het gevolg zijn van gehoorproblemen, organische afwijkingen, neurologische problemen, matige intelligentie, afwijkend mondgedrag, afwijkende mondmotoriek, een vertraagde spraakontwikkeling of onvoldoende aanbod uit de omgeving. Een persoon met spraakproblemen kan moeilijker verstaanbaar zijn. Soms voelt men zich hierdoor geïsoleerd of faalangstig of wordt men gepest. Een articulatiestoornis kan in een latere fase leiden tot lees- en spellingproblemen.
              </p>

              <h4 className={classes.title}>Soorten articulatiestoornissen</h4>
              <h6 className={classes.title}>Fonetische/fonemische articulatiestoornissen</h6>
              <p>
                Hier kan het gaan om een eenvoudige verkeerde articulatiegewoonte. Correct articuleren is een vaardigheid die dan aangeleerd moet worden.
              </p>
              <h6 className={classes.title}>Fonologische spraakstoornissen (kaderen binnen taalstoornissen)</h6>
              <p>
                <ul>
                  <li>
                    Deleties of reducties: Het weglaten van één of meerdere spraakklanken.<br/>
                    Bijvoorbeeld: fiets = ies, ster = ter, krant=kant, school=sool
                  </li>
                  <li>
                    Backing, fronting, stopping, ed.: Het vervangen van spraakklanken of spraakklankcombinaties.<br/>
                    Bijvoorbeeld koe = toe, roos = loos, bus = boes, vlieg = tieg
                  </li>
                  <li>
                    Hypo-/hypernasaliteit: De spraak klinkt nasaal, er zijn te weinig neusklanken. Het lijkt alsof iemand verkouden is (mama=baba) of er ontsnapt veel lucht door de neus. Je hoort snurkende geluiden (vaak bij [s]).
                  </li>
                </ul>
              </p>
              <h4 className={classes.title}>Therapie</h4>
              <p>
                Tijdens het onderzoek zal de logopedist nagaan wat de oorzaak van het articulatieprobleem is, en worden de problemen in kaart gebracht.
              </p>
              <p>
                Wanneer de therapie wordt opgestart, zal de logopedist het onderscheid tussen de foute en de goede articulatie uitleggen en aanleren. Dit gebeurt via luisteren, kijken en voelen. Vaak zijn ook specifieke mondmotorische oefeningen nodig om spieren in of rond de mond te versterken. Een correcte klank zal eerst geïsoleerd ingeoefend worden, vervolgens in lettergrepen, woorden en zinnen. Tenslotte moet ook de transfer naar spontane spraak gemaakt worden.
              </p>
              <p>
                Bij articulatietherapie is het belangrijk dat er naast de logopedische sessies ook dagelijks thuis geoefend wordt. Alleen dan zal snel vooruitgang gezien worden en behoudt de cliënt zijn motivatie.
              </p>
            </GridItem>
            <GridItem md={12} sm={12}>
              <h3 className={classes.title}>Therapeuten</h3>
              <p>
                Voor articulatieproblemen zal u onderzocht en behandeld worden door <a href="/team/lieve"><b>Lieve Mutton</b></a> of <a href="/team/lore"><b>Lore Van de Kelft</b></a>.
              </p>
            </GridItem>

          </GridContainer>
        </div>
      </div>
    </div>
  )
};
