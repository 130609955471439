import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Subject from "@material-ui/icons/Subject";
import Refresh from "@material-ui/icons/Refresh";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-pro-react/pages/landingPageSections/teamStyle.js";

import cardLieve from "assets/img/profile/lieve_card.jpg";
import cardElke from "assets/img/profile/elke_card.jpg";
import cardMadou from "assets/img/profile/madou_card.jpg";
import cardLore from "assets/img/profile/lore_card.jpg";

const useStyles = makeStyles(styles);

export default function SectionTeam() {
  const [activeRotate1, setActiveRotate1] = React.useState("");
  const [activeRotate2, setActiveRotate2] = React.useState("");
  const [activeRotate3, setActiveRotate3] = React.useState("");
  const [activeRotate4, setActiveRotate4] = React.useState("");
  const classes = useStyles();

  React.useEffect(() => {
    if (window) {
      window.addEventListener("resize", addStylesForRotatingCards);
    }
    addStylesForRotatingCards();
    return function cleanup() {
      if (window) {
        window.removeEventListener("resize", addStylesForRotatingCards);
      }
    };
  });
  const addStylesForRotatingCards = () => {
    var rotatingCards = document.getElementsByClassName(classes.cardRotate);
    var cardHeight = 0
    for (let i = 0; i < rotatingCards.length; i++) {
      var rotatingCard = rotatingCards[i];
      var cardFront = rotatingCard.getElementsByClassName(classes.front)[0];
      var cardBack = rotatingCard.getElementsByClassName(classes.back)[0];
      cardFront.style.height = "unset";
      cardFront.style.width = "unset";
      cardBack.style.height = "unset";
      cardBack.style.width = "unset";
      cardHeight = Math.max(cardHeight, cardFront.offsetHeight, cardBack.offsetHeight);
    }
    cardHeight += 16
    for (let i = 0; i < rotatingCards.length; i++) {
      rotatingCard = rotatingCards[i];
      cardFront = rotatingCard.getElementsByClassName(classes.front)[0];
      cardBack = rotatingCard.getElementsByClassName(classes.back)[0];
      cardFront.style.height = "unset";
      cardFront.style.width = "unset";
      cardBack.style.height = "unset";
      cardBack.style.width = "unset";
      var rotatingWrapper = rotatingCard.parentElement;
      var cardWidth = rotatingCard.parentElement.offsetWidth;
      rotatingWrapper.style.height = cardHeight + "px";
      rotatingWrapper.style["margin-bottom"] = 64 + "px";
      cardFront.style.height = cardHeight + "px";
      cardFront.style.width = cardWidth + "px";
      cardBack.style.height = cardHeight + "px";
      cardBack.style.width = cardWidth + "px";
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.team}>
        <GridContainer>
          <GridItem
            md={8}
            sm={8}
            className={classNames(
              classes.mrAuto,
              classes.mlAuto,
              classes.textCenter
            )}
          >
            <h2 className={classes.title}>Ons team</h2>
            <h5 className={classes.description}>
            </h5>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={3} md={3}>  
            <div
              className={
                classes.rotatingCardContainer +
                " " +
                classes.manualRotate +
                " " +
                activeRotate1
              }
            >
              <Card profile className={classes.cardRotate}>
                <div className={classes.front}>
                  <CardHeader image>
                    <a href="/team/lieve" onClick={e => e.preventDefault()}>
                      <img src={cardLieve} alt="..." />
                    </a>
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: `url(${cardLieve})`,
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody className={classes.cardBodyRotate}>
                    <h4 className={classes.cardTitle}>Lieve Mutton</h4>
                    <h6
                      className={
                        classes.cardCategory + " " + classes.cardDescription
                      }
                    >
                      Coördinator en behandelende logopediste
                    </h6>
                    <div className={classes.textCenter}>
                      <Button
                        round
                        color="primary"
                        onClick={() =>
                          setActiveRotate1(classes.activateRotate)
                        }
                      >
                        <Refresh /> Meer info
                      </Button>
                    </div>
                  </CardBody>
                </div>
                <div className={classes.back}>
                  <CardBody className={classes.cardBody}>
                    <h5 className={classes.cardTitle}>Lieve Mutton</h5>
                      <ul className={classes.cardDescription, classes.bold}>
                        <li>
                          <a href='/doelgroepen#articulatie'>Articulatieproblemen</a>
                        </li>
                        <li>
                          <a href='/doelgroepen#omft'>Afwijkende mondgewoonten (OMFT)</a>
                        </li>
                        <li>
                          <a href='/doelgroepen#taal'>Taalstoornissen</a>
                        </li>
                      </ul>
                    <div className={classes.textCenter}>
                      <Button round color="primary"
                        href="/team/lieve"
                        onClick={() =>
                          setActiveRotate1("")
                        }>
                        <Subject /> Profiel
                      </Button>
                    </div>
                    <br />
                    <Button link onClick={() => setActiveRotate1("")}>
                      <Refresh /> Terug...
                    </Button>
                  </CardBody>
                </div>
              </Card>
            </div>
          </GridItem>

          <GridItem xs={12} sm={3} md={3}>
            <div
              className={
                classes.rotatingCardContainer +
                " " +
                classes.manualRotate +
                " " +
                activeRotate2
              }
            >
              <Card profile className={classes.cardRotate}>
                <div className={classes.front}>
                  <CardHeader image>
                    <a href="/team/elke" onClick={e => e.preventDefault()}>
                      <img src={cardElke} alt="..." />
                    </a>
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: `url(${cardElke})`,
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody className={classes.cardBodyRotate}>
                    <h4 className={classes.cardTitle}>Elke Ven</h4>
                    <h6
                      className={
                        classes.cardCategory + " " + classes.cardDescription
                      }
                    >
                      Behandelende logopediste en Stottertherapeute
                    </h6>
                    <div className={classes.textCenter}>
                      <Button
                        round
                        color="primary"
                        onClick={() =>
                          setActiveRotate2(classes.activateRotate)
                        }
                      >
                        <Refresh /> Meer info
                      </Button>
                    </div>
                  </CardBody>
                </div>
                <div className={classes.back}>
                  <CardBody className={classes.cardBody}>
                    <h5 className={classes.cardTitle}>Elke Ven</h5>
                      <ul className={classes.cardDescription, classes.bold}>

                        <li>
                          <a href='/doelgroepen#stotteren'>Stotteren</a>
                        </li>
                        <li>
                          <a href='/doelgroepen#broddelen'>Broddelen</a>
                        </li>
                        <li>
                          <a href='/doelgroepen#mutisme'>Selectief mutisme</a>
                        </li>
                      </ul>
                    <div className={classes.textCenter}>
                      <Button round color="primary"
                        href="/team/elke"
                        onClick={() =>
                          setActiveRotate2("")
                        }>
                        <Subject /> Profiel
                      </Button>
                    </div>
                    <br />
                    <Button link onClick={() => setActiveRotate2("")}>
                      <Refresh /> Terug...
                    </Button>
                  </CardBody>
                </div>
              </Card>
            </div>
          </GridItem>

          <GridItem xs={12} sm={3} md={3}>
            <div
              className={
                classes.rotatingCardContainer +
                " " +
                classes.manualRotate +
                " " +
                activeRotate3
              }
            >
              <Card profile className={classes.cardRotate}>
                <div className={classes.front}>
                  <CardHeader image>
                    <a href="/team/madou" onClick={e => e.preventDefault()}>
                      <img src={cardMadou} alt="..." />
                    </a>
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: `url(${cardMadou})`,
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody className={classes.cardBodyRotate}>
                    <h4 className={classes.cardTitle}>Madou Parys</h4>
                    <h6
                      className={
                        classes.cardCategory + " " + classes.cardDescription
                      }
                    >
                      Behandelende logopediste
                    </h6>
                    <div className={classes.textCenter}>
                      <Button
                        round
                        color="primary"
                        onClick={() =>
                          setActiveRotate3(classes.activateRotate)
                        }
                      >
                        <Refresh /> Meer info
                      </Button>
                    </div>
                  </CardBody>
                </div>
                <div className={classes.back}>
                  <CardBody className={classes.cardBody}>
                    <h5 className={classes.cardTitle}>Madou Parys</h5>
                      <ul className={classes.cardDescription, classes.bold}>
                        <li>
                          <a href='/doelgroepen#neuro'>Neurogene taal-spraakstoornissen</a>
                        </li>
                        <li>
                          <a href='/doelgroepen#leerstoornissen'>Leerstoornissen</a>
                        </li>
                      </ul>
                    <div className={classes.textCenter}>
                      <Button round color="primary"
                        href="/team/madou"
                        onClick={() =>
                          setActiveRotate3("")
                        }>
                        <Subject /> Profiel
                      </Button>
                    </div>
                    <br />
                    <Button link onClick={() => setActiveRotate3("")}>
                      <Refresh /> Terug...
                    </Button>
                  </CardBody>
                </div>
              </Card>
            </div>
          </GridItem>

          <GridItem xs={12} sm={3} md={3}>
            <div
              className={
                classes.rotatingCardContainer +
                " " +
                classes.manualRotate +
                " " +
                activeRotate4
              }
            >
              <Card profile className={classes.cardRotate}>
                <div className={classes.front}>
                  <CardHeader image>
                    <a href="/team/lore" onClick={e => e.preventDefault()}>
                      <img src={cardLore} alt="..." />
                    </a>
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: `url(${cardLore})`,
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody className={classes.cardBodyRotate}>
                    <h4 className={classes.cardTitle}>Lore Van de Kelft</h4>
                    <h6
                      className={
                        classes.cardCategory + " " + classes.cardDescription
                      }
                    >
                      Behandelende logopediste en Stottertherapeute
                    </h6>
                    <div className={classes.textCenter}>
                      <Button
                        round
                        color="primary"
                        onClick={() =>
                          setActiveRotate4(classes.activateRotate)
                        }
                      >
                        <Refresh /> Meer info
                      </Button>
                    </div>
                  </CardBody>
                </div>
                <div className={classes.back}>
                  <CardBody className={classes.cardBody}>
                    <h5 className={classes.cardTitle}>Lore Van de Kelft</h5>
                      <ul className={classes.cardDescription, classes.bold}>
                      <li>
                        <a href='/doelgroepen#articulatie'>Articulatiestoornissen</a>
                      </li>
                      <li>
                        <a href='/doelgroepen#taal'>Taalstoornissen</a>
                      </li>
                      <li>
                        <a href='/doelgroepen#stotteren'>Stotteren</a>
                      </li>
                      <li>
                        <a href='/doelgroepen#leerstoornissen'>Leerstoornissen</a>
                      </li>
                      </ul>
                    <div className={classes.textCenter}>
                      <Button round color="primary"
                        href="/team/lore"
                        onClick={() =>
                          setActiveRotate4("")
                        }>
                        <Subject /> Profiel
                      </Button>
                    </div>
                    <br />
                    <Button link onClick={() => setActiveRotate4("")}>
                      <Refresh /> Terug...
                    </Button>
                  </CardBody>
                </div>
              </Card>
            </div>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
