/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @material-ui/icons
import Camera from "@material-ui/icons/Camera";
import Palette from "@material-ui/icons/Palette";
import People from "@material-ui/icons/People";
import Add from "@material-ui/icons/Add";
import Favorite from "@material-ui/icons/Favorite";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import NavPills from "components/NavPills/NavPills.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Badge from "components/Badge/Badge.js";
import Muted from "components/Typography/Muted.js";
import Parallax from "components/Parallax/Parallax.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import Button from "components/CustomButtons/Button.js";

import madou from "assets/img/profile/madou_square.jpg";

import profilePageStyle from "assets/jss/material-kit-pro-react/pages/profilePageStyle.js";

const useStyles = makeStyles(profilePageStyle);

export default function ProfileMadou() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);
  return (
    <div>
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={6}>
          <div className={classes.profile}>
            <div>
              <img src={madou} alt="..." className={imageClasses} />
            </div>
            <div className={classes.name}>
              <h3 className={classes.title}>Madou Parys</h3>
              <h6>Behandelende logopediste</h6>
            </div>
          </div>
        </GridItem>
      </GridContainer>
      <div className={classes.profileTabs}>
        <GridContainer>
          <GridItem
            xs={12}
            sm={12}
            md={6}
            className={classes.gridItem}>
            <h4 className={classes.title}>Achtergrond</h4>
            <p>
            Madou Parys is logopediste en werkt sinds 2017 in de praktijk. Na het behalen van de Master Logopedie aan de KU Leuven heeft ze zich gespecialiseerd in de behandeling van kinderen met leerproblemen en -stoornissen (dyslexie, dysorthografie en dyscalculie) en in de begeleiding van personen met niet-aangeboren hersenletsel (afasie, dysartrie en apraxie). Daarnaast richt ze zich op therapie bij communicatie- en slikproblemen als gevolg van neurodegeneratieve aandoeningen, waaronder Parkinson, ALS en dementie (o.a. Primair Progressieve Afasie).  
            </p>
            <p>
            Door het volgen van bijscholingen, studiedagen en het raadplegen van vakliteratuur werkt ze op een wetenschappelijk onderbouwde en individuele manier. Ze bekijkt de cliënt in zijn geheel en plaatst de talenten en interesses van de persoon centraal, met als doel samen tot het beste resultaat te komen.
            </p>
            </GridItem>
          <GridItem
            xs={12}
            sm={12}
            md={5}
            className={classes.gridItem}>
            <h4 className={classes.title}>Vakgebieden</h4>
            <ul className={classes.job}>
              <li>
                <a href='/doelgroepen#neuro'>Neurogene taal-spraakstoornissen</a>
              </li>
              <li>
                <a href='/doelgroepen#leerstoornissen'>Leerstoornissen</a>
              </li>
            </ul>
            <hr />
            <h4 className={classes.title}>Opleidingen</h4>
            <ul>
              <li>Master in de logopedische en audiologische wetenschappen (KU Leuven, 2017)</li>
              <li>RekenTrapperS (Eureka Leuven, 2017)</li>
              <li>Een behandeltraject voor personen met een neurodegeneratieve aandoening (Thomas More, 2017)</li>
              <li>Cognitieve communicatie bij volwassenen met NAH (Thomas More, 2018)</li>
              <li>Logopedie bij de ziekte van Parkinson (Artevelde Academy, 2024)</li>
              <li>Cognitieve communicatiestoornissen na rechterhemisfeerschade (F. Paemeleire, 2024)</li>
              <li>Jaarlijks volgt Madou congressen, bijscholingen en studiedagen rond leerstoornissen (dyslexie, dysorthografie, dyscalculie). </li>
            </ul>
            <hr />
          </GridItem>
        </GridContainer>

      </div>
      <Clearfix />
    </div>
    </div>
  );
}
