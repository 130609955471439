/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";
import PinDrop from "@material-ui/icons/PinDrop";
import Phone from "@material-ui/icons/Phone";
import Mail from "@material-ui/icons/Email";
import BusinessCenter from "@material-ui/icons/BusinessCenter";
// core components
import Header from "components/Header/Header.js";
import Parallax from "components/Parallax/Parallax.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import LogoFooter from "components/Footer/LogoFooter.js";
// sections for this page
import HeaderLinks from "components/Header/HeaderLinks0.js";

import ratePageStyle from "assets/jss/material-kit-pro-react/pages/ratePageStyle.js";

const useStyles = makeStyles(ratePageStyle);

const paragraphs = [
  {
    text: `
      Wij zijn gedeconventioneerde logopedisten. 
      Dit betekent dat wij niet de honoraria hanteren zoals die door het RIZIV werden vastgelegd. 
      Rechthebbenden met voorkeursregeling hebben recht op het officiële tarief en krijgen tot 90% van de kosten terugbetaald (zie RIZIV).
    `
  },
  {
    text: `
      Voor de meeste van onze behandelingen kan u genieten van een tussenkomst in de kosten via uw ziekenfonds. 
      Wanneer uw dossier valt onder de voorwaarden van het RIZIV, wordt ongeveer 58% van de kosten terugbetaald. 
      Wanneer uw dossier niet voor goedkeuring door het RIZIV in aanmerking komt, kan beroep gedaan worden op tussenkomst via de ‘aanvullende verzekering’ van uw ziekenfonds. 
      Deze tarieven van tussenkomst verschillen van ziekenfonds tot ziekenfonds. 
      Wij informeren u graag over de verschillende mogelijkheden of vragen na welke tussenkomst voor u van toepassing is.
    `
  },
  {
    text: `
      Voor therapie op school of thuis worden vervoerskosten in rekening gebracht (€ 5,00/sessie).
    `
  }
];

const cards = [
  { title: "Therapie van 30 minuten", price: 40 },
  { title: "Therapie van 60 minuten", price: 80 },
  { title: "Overleg op school/netwerk", price: 47 },
  { title: "Onderzoek aanvangsbilan", price: 54, unit: "/30 minuten" },
  { title: "Onderzoek evolutiebilan", price: 83},
  { title: "Afwezigheid of laattijdig annuleren", price: 30, unit: "/30 minuten", note: "(Geen tegemoetkoming door RIZIV of ziekenfonds)" }
];

export default function RatePage() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });

  const classes = useStyles();

  const renderParagraphs = paragraphs.map((paragraph, index) => (
    <GridItem md={12} sm={12} key={index}>
      <p dangerouslySetInnerHTML={{ __html: paragraph.text }} />
    </GridItem>
  ));

  const renderCards = cards.map((card, index) => (
    <GridItem xs={12} sm={4} md={4} className={classes.mlAuto} key={index}>
      <Card pricing>
        <CardBody pricing>
          <h6 className={classes.cardDescription}>{card.title}</h6>
          <h1 className={classes.cardTitle}>
            <small>€</small> {card.price} {card.unit && <small>{card.unit}</small>}
          </h1>
          {card.note && <p className={classes.cardDescription}>{card.note}</p>}
        </CardBody>
      </Card>
    </GridItem>
  ));

  return (
    <div>
      <Header
        brand="Logopedie | Lieve Mutton"
        links={<HeaderLinks dropdownHoverColor="primary" />}
        fixed
        changeColorOnScroll={{
          height: 250,
          color: "dark"
        }}
        color="transparent"
      />
      <Parallax
        image={require("assets/img/examples/office5.jpg")}
        filter="dark"
        className={classes.parallax}
      />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.rateContent}>
          <div className={classes.container}>
            <h2 className={classes.title}>Tarieven</h2>
            <GridContainer>
              {renderParagraphs}
              {renderCards}
            </GridContainer>
          </div>
        </div>
      </div>
      <div className={classes.empty} />
      <LogoFooter />
    </div>
  );
}