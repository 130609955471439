/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Footer from "components/Footer/Footer.js";

import lieve from "assets/img/profile/lieve_square.jpg";
import elke from "assets/img/profile/elke_square.jpg";
import madou from "assets/img/profile/madou_square.jpg";
import lore from "assets/img/profile/lore_square.jpg";
import logo_inv from "assets/img/logo/logopedie_logo_crop_inv.png";

import styles from "assets/jss/material-kit-pro-react/components/footerStyle.js";

const useStyles = makeStyles(styles);

export default function LogoFooter() {
  React.useEffect(() => {});
  const classes = useStyles();
  return (
    <Footer
      theme="dark"
      content={
        <div>
          <div className={classes.left}>
            <List className={classes.list}>
              <ListItem className={classes.inlineBlock}>
                <a
                  href="/"
                  className={classes.block}
                >
                  Home
                </a>
              </ListItem>
              <ListItem className={classes.inlineBlock}>
                <a
                  href="/links"
                  className={classes.block}
                >
                  Links
                </a>
              </ListItem>
              <ListItem className={classes.inlineBlock}>
                <a
                  href="/gpdr"
                  className={classes.block}
                >
                  GDPR
                </a>
              </ListItem>
              <ListItem className={classes.inlineBlock}>
                <a
                  href="/contact"
                  className={classes.block}
                >
                  Contact
                </a>
              </ListItem>
            </List>
          </div>
          <div className={classes.right}>
            Copyright &copy; {1900 + new Date().getYear()}{" "}
            All Rights Reserved.
          </div>
        </div>
      }
    >
      <GridContainer>
        <GridItem xs={12} sm={4} md={4}>
          <img
            src={logo_inv}
            alt="..."
            className={classes.imgRounded + " " + classes.imgFluid}
          />
        </GridItem>
        <GridItem xs={12} sm={4} md={4}>
          <h5>Ons adres</h5>
          <p>
            Logopedie Lieve Mutton<br/>
            Platte Lostraat 580 A<br/>
            3010 Kessel-Lo
          </p>
          <p>
            <li>Telefoonnummer: +32 498/80 75 63</li>
            <li>E-mailadres: <a href="mailto:lieve.mutton@hotmail.com">lieve.mutton@hotmail.com</a></li>
            <li>Website: <a href='/'>www.logopedielievemutton.be</a></li>
          </p>
        </GridItem>
        <GridItem xs={12} sm={4} md={4}>
          <h5>Therapeuten</h5>
          <div className={classes.galleryFeed}>
            <a href="/team/lieve">
              <img
                src={lieve}
                className={classNames(
                  classes.img,
                  classes.imgRaised,
                  classes.imgRounded
                )}
                alt="..."
              />
            </a>
            <a href="/team/elke">
              <img
                src={elke}
                className={classNames(
                  classes.img,
                  classes.imgRaised,
                  classes.imgRounded
                )}
                alt="..."
              />
            </a>
            <a href="/team/madou">
              <img
                src={madou}
                className={classNames(
                  classes.img,
                  classes.imgRaised,
                  classes.imgRounded
                )}
                alt="..."
              />
            </a>
            <a href="/team/lore">
              <img
                src={lore}
                className={classNames(
                  classes.img,
                  classes.imgRaised,
                  classes.imgRounded
                )}
                alt="..."
              />
            </a>
          </div>
        </GridItem>
      </GridContainer>
    </Footer>
  );
}
