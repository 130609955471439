import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// Core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import targetSectionStyle from "assets/jss/material-kit-pro-react/pages/targetPageSections/targetPageSectionStyle.js";

const useStyles = makeStyles(targetSectionStyle);

export default function SectionMutisme({ ...rest }) {
  const classes = useStyles();
  return (
    <div className={"cd-section " + classes.sectionDark} {...rest} >
      <div className={classes.container}>
        <div className={classes.targetContent}>
        <h2 className={classes.titleWhite}>Selectief mutisme</h2>
        <GridContainer className={classes.descriptionWhite}>
          <GridItem md={4} sm={4}>
            <h3 className={classes.title}>Samengevat</h3>
            <p>
            Selectief mutisme of extreme spreekangst is een angststoornis waarbij het kind in bepaalde sociale situaties niet praat.
            </p>
          </GridItem>

          <GridItem md={8} sm={8}>
            <h3 className={classes.title}></h3>
            <h4 className={classes.title}>Wat is Selectief mutisme?</h4>
            <p>
            Selectief mutisme is een angststoornis waarbij een kind extreme spreekangst ervaart in specifieke sociale situaties, zoals op school of in contact met volwassenen. Dit staat vaak in contrast met spreeksituaties thuis, waar het kind wel vlot en comfortabel praat. Selectief mutisme treft 1 tot 7 op 1000 kinderen, meestal tussen 3 en 5 jaar oud, en komt vaker voor bij meisjes dan bij jongens.
            </p>
            <h4 className={classes.title}>Therapie</h4>
            <p>
            Cognitieve gedragstherapie kan helpen om de spreekangst te overwinnen. Daarnaast gebruiken we ook aspecten van de positieve psychologie, waardoor we oog hebben voor positieve emoties, de talenten en sterktes van de persoon met selectief mutisme wat therapeut-patiëntrelatie bevordert, de eigen veerkracht vergroot en motiverend werkt voor de therapie.
            </p>

          </GridItem>

          <GridItem md={12} sm={12}>
            <h3 className={classes.title}>Therapeuten</h3>
            <p>
              Voor selectief mutisme zal u onderzocht en behandeld worden door <a href="/team/elke"><b>Elke Ven</b></a>.
            </p>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  </div>
  )
};
