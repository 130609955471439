import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// Core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import targetSectionStyle from "assets/jss/material-kit-pro-react/pages/targetPageSections/targetPageSectionStyle.js";

const useStyles = makeStyles(targetSectionStyle);

export default function SectionAutisme({ ...rest }) {
  const classes = useStyles();
  return (
    <div className={"cd-section "} {...rest} >
      <div className={classes.targetContent}>
        <div className={classes.container}>
          <h2 className={classes.title}>Autismestoornissen</h2>
          <GridContainer>
            <GridItem md={4} sm={4}>
              <h3 className={classes.title}>Samengevat</h3>
              <p>
                Autisme is een aangeboren stoornis in de hersenen. De stoornis uit zich op verschillende manieren en in verschillende gradaties. We benoemen de stoornissen in het spectrum als ASS. Hieronder vallen bijvoorbeeld  klassiek autisme, stoornis van Asperger en PDD-NOS. Problemen in de sociale interactie en communicatie zijn twee van de drie gedragskenmerken van autisme. Personen met ASS worden vaak verkeerd begrepen en vinden het lastig contacten met anderen te leggen. Ze zijn vaak sociaal onhandig en vallen bijvoorbeeld iemand steeds in de rede. Ze nemen iets letterlijk dat figuurlijk is bedoeld. Dat leidt vaak tot onduidelijkheden en ergernissen.
              </p>
              <p>
                Als logopedist begeleiden we personen met ASS met taalproblemen of bijvoorbeeld bij comorbide stoornissen, zoals leerstoornissen.
              </p>
            </GridItem>

            <GridItem md={8} sm={8}>
              <h3 className={classes.title}></h3>
              <h4 className={classes.title}>Kenmerken ASS</h4>
              <h6 className={classes.title}>Verstoorde communicatie</h6>
              <p>
                Sommige mensen met autisme spreken helemaal niet, maar het grootste deel spreekt wel. Er vallen dingen op in het gebruiken of begrijpen van taal. De meeste mensen met autisme vinden het moeilijk om een gesprek te beginnen of vol te houden, zeker bij vreemde mensen. Er kan sprake zijn van eigenaardig taalgebruik. Zo kan er sprake zijn van naspreken van de gesprekspartner zonder dat dit inhoudelijk klopt in het gesprek (echolalie wordt dat genoemd). Ook humor en abstract taalgebruik is iets waar mensen met autisme heel wat moeite mee hebben. Logopedisten houden zich bezig met het verbeteren van de communicatie.
              </p>
              <h6 className={classes.title}>Interactieproblemen</h6>
              <p>
                Iemand met autisme kan zich volledig afsluiten van contact met anderen. Er wordt bijvoorbeeld alleen contact gezocht als het echt niet anders kan en als hij of zij iemand nodig heeft voor de eigen behoeften. Dit komt vooral veel voor bij klassiek autisme. Bij andere vormen van autisme kan er wel sprake zijn van spontaan contact, maar ontbreekt vaak de wederkerigheid. Psychologen werken met een patiënt rond deze interactieproblemen.
              </p>
              <h6 className={classes.title}>Stereotiepe patronen</h6>
              <p>
                Stereotiepe patronen zijn vaak herhalingen die zich uiten in gedrag, belangstelling en activiteiten. Sommige mensen zullen geobsedeerd bezig zijn met bepaalde voorwerpen of onderwerpen. Dit kunnen ook hobby’s of niet-functionele gewoonten zijn of stereotiepe bewegingen (bijvoorbeeld ‘wiegen’). Mensen met autisme klampen zich vaak vast aan bepaalde gewoonten, starre patronen, bepaalde volgorde van handelingen of vaste routines. Ze kunnen ernstig in paniek raken als er een detail in de omgeving verandert (zoals een ander poster aan de muur waar ze altijd langs lopen). Ook is er vaak sprake van zeer rigide denkpatronen. Deze denkpatronen bieden bescherming tegen teveel prikkels. Zo wordt de enge en verwarrende buitenwereld weer controleerbaar.
              </p>
              <h6 className={classes.title}>Informatieverwerking</h6>
              <p>
                Veel mensen met autisme hebben problemen met de verwerking van informatie. Zo kan er een overgevoeligheid zijn voor bepaalde prikkels, trage informatieverwerking, moeite met het schakelen van de ene situatie naar de andere of het verwerken van non-verbale informatie, bijvoorbeeld gebaren of lichaamstaal.
              </p>


              <h4 className={classes.title}>Therapie</h4>
              <p>
                Een logopedist zal voor iemand met autisme de spraak-taalontwikkeling proberen bevorderen, de communicatie met andere mensen proberen te verbeteren en de wereld een klein stukje duidelijker proberen te maken.
              </p>
              <p>
                Vaak zijn er problemen met het begrijpen van de taal en het gebruik van de taal. De logopedist observeert en onderzoekt welke aspecten van de taalontwikkeling verstoord zijn en maakt hier een persoonlijk behandelplan op. De behandeling van taalproblemen moet echter deel uitmaken van een breder behandelproces, waarvan ouders, school en andere externe begeleiders deel uitmaken.
              </p>
              <p>
                In sommige gevallen is het noodzakelijk een logopedische therapie te combineren met een psychomotore begeleiding of psychotherapie. Bij complexe ontwikkelingsproblemen kan doorverwezen worden naar een CAR (centrum voor ambulante revalidatie). Continuïteit en onderling overleg is zeer belangrijk voor het slagen van de therapie. Na elke therapiesessie wordt uitleg gegeven over de inhoud en de vorderingen.
              </p>

            </GridItem>

            <GridItem md={12} sm={12}>
              <h3 className={classes.title}>Therapeuten</h3>
              <p>
                Voor problemen die kaderen binnen ASS zal u onderzocht en behandeld worden
                door <a href="/team/lieve"><b>Lieve Mutton</b></a>.
              </p>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  )
};
