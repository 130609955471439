import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// Core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import targetSectionStyle from "assets/jss/material-kit-pro-react/pages/targetPageSections/targetPageSectionStyle.js";

const useStyles = makeStyles(targetSectionStyle);

export default function SectionStutter({ ...rest }) {
  const classes = useStyles();
  return (
    <div className={"cd-section " + classes.sectionDark} {...rest} >
      <div className={classes.targetContent}>
      <div className={classes.container}>
        <h2 className={classes.titleWhite}>Stotteren</h2>
          <GridContainer className={classes.descriptionWhite}>
            <GridItem md={4} sm={4}>
              <h3 className={classes.title}>Samengevat</h3>
              <p>
              Stotteren is het onvrijwillig herhalen, verlengen of blokkeren van klanken tijdens het spreken. Dit kan gepaard gaan met spanningen en ongemakkelijke gevoelens. Mensen die stotteren proberen soms het spreken te vermijden of het stotteren te onderdrukken, wat kan wijzen op een negatieve ontwikkeling. Therapie kan al op jonge leeftijd helpen. Daarom is het belangrijk om snel contact op te nemen met een stottertherapeut om ernstige gevolgen voor de spraak, maar ook op psychisch, sociaal en emotioneel vlak, te voorkomen.
              </p>
            </GridItem>

            <GridItem md={8} sm={8}>
              <h3 className={classes.title}></h3>
              <h4 className={classes.title}>Wat is stotteren?</h4>
              <p>
              Stotteren begint meestal op jonge leeftijd, vaak vóór of rond de leeftijd van 3 jaar. Zelfs op deze leeftijd is behandeling mogelijk en kan het zelfs noodzakelijk zijn. Stotteren is een complexe stoornis waarbij genetische en omgevingsfactoren, samen met eigenschappen van de persoon die stottert, een rol spelen.
              </p>
              <p>
              Er zijn drie soorten <b>kernstotters</b>:
                <ul>
                  <li>
                  Herhalingen van klanken, lettergrepen of korte woorden (bijv. i-i-ik, pa-pa-papa).
                  </li>
                  <li>
                  Verlengingen van klanken (bijv. mmmama, iiiik).
                  </li>
                  <li>
                  Blokkeringen waarbij het spreken tijdelijk stopt (bijv. i.k, d.at).
                  </li>
                </ul>
              </p>
              <p>
              Stotteren kan gepaard gaan met negatieve gedachten, emoties en veranderingen in het gedrag, zowel algemeen als specifiek bij het spreken. Sommige kinderen of volwassenen ontwikkelen gedragingen om het stotteren te verbergen of te verminderen. Een erkende stottertherapeut kan helpen deze reacties te identificeren. Tijdens een uitgebreid onderzoek worden de verschillende aspecten die bijdragen aan het stotteren in kaart gebracht, waardoor we de stotterernst kunnen bepalen en vervolgens een behandelplan kunnen opstellen.
              </p>

              <h4 className={classes.title}>Therapie</h4>
              <p>
              <b>Sociaal-cognitieve gedragstherapie</b> richt zich op de verschillende aspecten van stotteren: gedachten, gevoelens, gedrag en de invloed van de omgeving. De therapie identificeert en vermindert factoren die stotteren uitlokken, versterken of in stand houden. Het doel is om de ernst van het stotteren te verminderen door de stotterfrequentie en -duur te verlagen, secundaire gedragingen te elimineren en het spreken weer aangenaam te maken. Dit kan gebeuren via directe of indirecte therapie, indien het kind nog (zeer) jong is.
              </p>
              <p>
              Als ouder of partner speelt u een cruciale rol in het toepassen van veranderingen buiten de therapie. U krijgt tijdens de therapie en oudercursus verdere informatie over stotteren.
              </p>
              <p>
              De therapie bestaat uit individuele sessies van 30 of 60 minuten, waarbij ouders of partners indien nodig aanwezig zijn. Deelname aan de oudercursus is verplicht en essentieel voor het succes van de therapie.
              </p>
              <p>
              Daarnaast hebben we ook oog voor de talenten van de persoon die stottert. Via aspecten van de <b>positieve psychologie</b> stimuleren we het ervaren van positieve emoties, wat de weerbaarheid vergroot en het zelfvertrouwen laat toenemen.
              </p>

              <h4 className={classes.title}>Twijfelt u of uw kind stottert? </h4>
              <p>
              Bij twijfel tussen stotteren en normale onvloeiendheden is het raadzaam om een stottertherapeut te raadplegen. Hoe sneller stotteren wordt onderzocht en behandeld, hoe groter de kans op (volledig) herstel en het voorkomen van negatieve ontwikkelingen. De therapeut stemt de therapie af met de ouders, afhankelijk van de ernst van het stotteren en de leeftijd van het kind. Voor vragen of meer informatie kunt u vrijblijvend contact opnemen.
              </p>
            </GridItem>

            <GridItem md={12} sm={12}>
              <h3 className={classes.title}>Therapeuten</h3>
              <p>
                Voor stotteren zal u onderzocht en behandeld worden door <a href="/team/elke"><b>Elke Ven</b></a> of <a href="/team/lore"><b>Lore Van de Kelft</b></a>.
              </p>
            </GridItem>
          </GridContainer>
      </div>
    </div>
    </div>
  )
};
